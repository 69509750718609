<template>
    <section>
        
        <section class="info-container">
            <!-- 问题记录 -->
            <section class="info-item-wrap">
                <p class="info-title">问题记录</p>
                <a-row class="m-t-18">
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">问题部位</a-col>
                            <a-col class="val">{{recordInfo.position}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">问题等级</a-col>
                            <a-col class="val">{{recordInfo.rankName}}</a-col>
                        </a-row>
                    </a-col>
                    
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">检查人</a-col>
                            <a-col class="val">{{recordInfo.checkPerson}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">检查时间</a-col>
                            <a-col class="val">{{recordInfo.checkTime}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">责任单位</a-col>
                            <a-col class="val">{{recordInfo.responsibilityCompany}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">整改人</a-col>
                            <a-col class="val">{{recordInfo.rectifyPersonName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">整改时限</a-col>
                            <a-col class="val">{{recordInfo.rectifyDuration}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                 <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">复核人</a-col>
                            <a-col class="val">{{recordInfo.recheckPersonName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">通知人</a-col>
                            <a-col class="val">{{recordInfo.notiPersonName}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                 <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">问题描述</a-col>
                            <a-col class="val">{{recordInfo.description}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">补充说明</a-col>
                            <a-col class="val">{{recordInfo.extra}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">整改要求</a-col>
                            <a-col class="val">{{recordInfo.rectifyStandard}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">相关照片</a-col>
                            <a-col class="val upload-preview-wrap">
                                <a class="file-item m-r-10" v-for="(item,ind) in recordInfo.photo" :key="ind">
                                    <preview-image :data="item" :size="100" />
                                </a>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </section>
            <!-- 整改记录 -->
            <section class="info-item-wrap" v-if="rectifyRec.id">
                <p class="info-title">整改记录</p>
                <section style="background:rgba(0,0,0,0.2);padding:16px" class="m-b-10">
                    <a-row>
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">整改状态</a-col>
                                <a-col class="val">{{recordInfo.status === '-1' ? '已撤回' : recordInfo.status === '1' ? '待复核' : recordInfo.status === '2' ? '已关闭' : '待整改'}}</a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">整改人</a-col>
                                <a-col class="val">{{recordInfo.rectifyPersonName}}</a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">整改时间</a-col>
                                <a-col class="val">{{rectifyRec.rectifyTime}}</a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <a-row class="info-item">
                                <a-col class="key">整改说明</a-col>
                                <a-col class="val">{{rectifyRec.rectifyContent}}</a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <a-row class="info-item">
                                <a-col class="key">相关照片</a-col>
                                <a-col class="val upload-preview-wrap">
                                    <a class="file-item m-r-10" v-for="(item,ind) in rectifyRec.photo" :key="ind">
                                        <preview-image :data="item" :size="100" />
                                    </a>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </section>
            </section>

            <section class="info-item-wrap  m-b-18" v-if="userId === recordInfo.rectifyPerson && recordInfo.status ==='0' && actionType == 2">
                <p class="info-title">
                    问题整改
                    <a-button class="f-right" type="primary" @click="showSelectUserModal">问题移交</a-button>
                </p>
                <a-form class="form-container" :form="form" layout="inline" style="background:rgba(0,0,0,0.16);padding:16px">
                    <section class="form-item">
                        <a-row>
                            <a-form-item label="整改说明">
                                <a-textarea rows="4" style="width:800px" v-decorator="['rectifyContent',{rules:[{required:true,message:'请输入整改说明'},{max:200,message:'最大输入200个字符'}]}]"></a-textarea>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-form-item label="整改照片">
                                <a-row type="flex">
                            <a-row class="upload-preview-wrap">
                                <a v-for="(item,key) in rectifyFile" :key="key" class="file-item m-r-10">
                                    <preview-image :data="item" :size="100" />
                                    <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                                </a>
                            </a-row>
                            <upload v-decorator="['photo',{rules:[{required:true,message:'请上传整改照片'}]}]" style="display:inline-block;width:100px" @func="getRectifyFile" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']"/>
                        </a-row>
                            </a-form-item>
                        </a-row>
                        <a-row class="form-btn-group">
                            <a-col :span="16" style="padding-left:80px">
                                <a-button @click="rectifySubmit" :disabled="submitLoadding" :loading="submitLoadding">提交整改</a-button>
                                <a-button @click="$router.back()">取消</a-button>
                            </a-col>
                        </a-row>
                    </section>
                </a-form>
            </section>
             <!-- 复核记录 -->
            <section class="info-item-wrap" v-if="recheckRec.id">
                <p class="info-title">复核记录</p>
                <section style="background:rgba(0,0,0,0.2);padding:16px" class="m-b-10" >
                        <a-row>
                            <a-col :span="8">
                                <a-row class="info-item">
                                    <a-col class="key">复核状态</a-col>
                                    <a-col class="val">{{recordInfo.rectifyFinished === '1' ? '整改完成' : '退回整改'}}</a-col>
                                </a-row>
                            </a-col>
                            <a-col :span="8">
                                <a-row class="info-item">
                                    <a-col class="key">复核人</a-col>
                                    <a-col class="val">{{recordInfo.recheckPersonName}}</a-col>
                                </a-row>
                            </a-col>
                            <a-col :span="8">
                                <a-row class="info-item">
                                    <a-col class="key">复核时间</a-col>
                                    <a-col class="val">{{recheckRec.recheckTime}}</a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="16">
                                <a-row class="info-item">
                                    <a-col class="key">复核说明</a-col>
                                    <a-col class="val">{{recheckRec.recheckContent}}</a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="16">
                                <a-row class="info-item">
                                    <a-col class="key">相关照片</a-col>
                                    <a-col class="val upload-preview-wrap">
                                        <a class="file-item m-r-10" v-for="(item,ind) in recheckRec.photo" :key="ind">
                                            <preview-image :data="item" :size="100" />
                                        </a>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                         
                </section>
            </section>

            <section class="info-item-wrap m-b-18" v-if="userId ===recordInfo.recheckPerson && recordInfo.status ==='1' && actionType ==3">
                <p class="info-title">问题复核</p>
                <a-form class="form-container" :form="form" layout="inline" style="background:rgba(0,0,0,0.16);padding:16px">
                    <section class="form-item m-t-18">
                        <a-row>
                            <a-form-item label="复核结果" >
                                <a-radio-group v-decorator="['finished',{rules:[{required:true,message:'请输入复核结果'}]}]">
                                    <a-radio value="1">整改完成</a-radio>
                                    <a-radio value="0">退回整改</a-radio>
                                </a-radio-group>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-form-item label="复核说明">
                                <a-textarea rows="4" style="width:800px" v-decorator="['recheckContent',{rules:[{required:true,message:'请输入复核说明'},{max:200,message:'最大输入200个字符'}]}]"></a-textarea>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-form-item label="复核照片">
                                <a-row type="flex">
                            <a-row class="upload-preview-wrap">
                                <a v-for="(item,key) in recheckFile" :key="key" class="file-item m-r-10">
                                    <a-avatar  shape="square" :size="100" :src="item" />
                                    <a class="close-icon" title="删除" @click="deleteRecheckFile(item)"><a-icon type="close"></a-icon></a>
                                </a>
                            </a-row>
                            <upload v-decorator="['photo',{rules:[{required:true,message:'请上传复核照片'}]}]" style="display:inline-block;width:100px" @func="getRecheckFile" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']"/>
                        </a-row>
                            </a-form-item>
                        </a-row>
                        <a-row class="form-btn-group">
                            <a-col :span="16" style="padding-left:80px">
                                <a-button @click="recheckSubmit" :disabled="submitLoadding" :loading="submitLoadding">确认提交</a-button>
                                <a-button @click="$router.back()">取消</a-button>
                            </a-col>
                        </a-row>
                    </section>
                </a-form>
            </section>
            <!-- 过程记录 -->
            <section class="info-item-wrap" v-if="recordInfo.logList.length">
                <p class="info-title">过程记录</p>
                <section style="background:rgba(0,0,0,0.18);padding:16px;max-height:400px" class="m-b-10 scroll-container" >
                    <li v-for="(item,key) in recordInfo.logList" :key="key" class="m-b-8">
                        <span class="m-r-10">{{item.personTitle}}</span>
                        <span class="m-r-10">{{item.content}}</span>
                        <span class="m-r-10">{{item.createTime}}</span>
                        <span class="m-r-10">{{item.content}}</span>
                    </li>
                </section>
            </section>
        </section>
        
        <a-modal width="1080px" class="modal-container" v-model="selectUserVisible" title="人员列表" :footer="false" :destroyOnClose="true" >
            <select-user :selectMode="'single'" @ok="safeRecordRectifyForward" @cancel="selectUserVisible = false" />
        </a-modal>
    </section>
</template>

<script>
import selectUser from '@/components/selectUser'
import previewImage from '@/components/previewImage'
import upload from '@/components/upload'
import moment from 'moment'
export default {
    data(){
        return{
            recordInfo:{
                logList:[],
                
            },
            rectifyRec:{},      //整改记录
            rectifyFile:[],     //整改照片
            recheckRec:{},      //复核记录
            recheckFile:[],      //复核图片列表
            selectUserVisible:false
        }
    },
    components:{previewImage,upload,selectUser},
    computed:{
        rid(){
            return this.$route.query.id
        },
        userId(){
            return this.$store.state.userInfo.userId
        },
        actionType(){
            return this.$route.query.type
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeRecordDetails()
    },
    methods:{
        //问题移交
        showSelectUserModal(){
            this.selectUserVisible = true
        },
        safeRecordRectifyForward(rectifyPersonList){
            let rectifyPerson = rectifyPersonList[0].id
            this.$api.safeRecordRectifyForward({safeRecordId:this.rid,rectifyPerson}).then(res =>{
                if(res.code === 200){
                    this.$router.back()
                }
            })
        },
        //查询巡检记录详情
        querySafeRecordDetails(){
            this.$api.querySafeRecordDetails(this.rid).then(res =>{
                if(res.code === 200){
                    this.recordInfo = res.data || {}
                    // this.recordInfo.photo = this.recordInfo.photo ? JSON.parse(this.recordInfo.photo) : []
                    this.recordInfo.photo = this.recordInfo.photo ? this.recordInfo.photo.split(',') : []
                    this.recordInfo.logList = (res.data && res.data.logList) || []  //过程记录
                    this.recheckRec = (res.data && res.data.recheckRec) || {}    //复查记录
                    // this.recheckRec.photo = this.recheckRec.photo ? JSON.parse(this.recheckRec.photo) : []
                    this.recheckRec.photo = this.recheckRec.photo ? this.recheckRec.photo.split(',') : []
                    //整改记录
                    this.rectifyRec = (res.data && res.data.rectifyRec) || {}  
                   
                    //this.rectifyRec.photo = this.rectifyRec.photo ? JSON.parse(this.rectifyRec.photo) : []
                    this.rectifyRec.photo = this.rectifyRec.photo ? this.rectifyRec.photo.split(',') : []
                }
            })
        },


        //整改
        getRectifyFile(file){
            this.rectifyFile.push(file.codeUrl)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.rectifyFile.length ? this.rectifyFile.join(',') : ''
                })
            })
        },
        deleteFile(file){
            this.rectifyFile = this.rectifyFile.filter(item => item != file)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.rectifyFile.length ? this.rectifyFile.join(',') : ''
                })
            })
        },
        rectifySubmit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    formData.rectifyTime = moment().format('YYYY-MM-DD HH:mm:ss')
                    formData.rectifyPerson = this.recordInfo.rectifyPerson
                    formData.safeRecordId = this.rid
                    this.$api.safeRecordRectify(formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        },



        //复核
        getRecheckFile(file){
            this.recheckFile.push(file.codeUrl)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.recheckFile.length ? this.recheckFile.join(',') : ''
                })
            })
        },
        deleteRecheckFile(file){
            this.recheckFile = this.recheckFile.filter(item => item != file)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.recheckFile.length ? this.recheckFile.join(',') : ''
                })
            })
        },
        recheckSubmit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    formData.recheckTime = moment().format('YYYY-MM-DD HH:mm:ss')
                    formData.recheckPerson = this.recordInfo.recheckPerson
                    formData.safeRecordId = this.rid
                    this.$api.safeRecordRecheck(formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }


    }
}
</script>